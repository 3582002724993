import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    step: 1,
    pageLoading: false,

    days: [],
    selectedDay: null,

    times: [],
    selectedTime: null,

    rooms: [],
    selectedRoom: null,

    activities: [],
    selectedActivity: null,

    reservationDetails: null,
    customAnswers: {},

    authenticatedUser: null,

    bands: [],
    selectedBand: null,

    error: null,
    message: null
  },
  mutations: {
    setDays(state, val) {
      val.forEach(day => {
        state.days.push(day)
      })
    },
    setSelectedDay(state, val) {
      state.selectedDay = val
    },
    setLoading(state, val) {
      state.pageLoading = val
    },

    nextStep(state) {
      state.step += 1
    },
    previousStep(state) {
      state.step -= 1
    },
    setStep(state, step) {
      state.step = step
    },

    setTimes(state, val) {
      state.times = val
    },
    setSelectedTime(state, val) {
      state.selectedTime = val
    },

    setRooms(state, val) {
      state.rooms = val
    },
    setSelectedRoom(state, val) {
      state.selectedRoom = val
    },

    setActivities(state, val) {
      state.activities = val
    },
    setSelectedActivity(state, val) {
      state.selectedActivity = val
    },

    setReservationDetails(state, val) {
      state.reservationDetails = val
    },
    setAuthenticatedUser(state, val) {
      state.authenticatedUser = val
    },

    setSelectedBand(state, val) {
      state.selectedBand = val
    },
    setBands(state, val) {
      state.bands = val
    },

    setError(state, val) {
      state.error = val
    },
    setMessage(state, val) {
      state.message = val
    },
    setCustomAnswers(state, val) {
      state.customAnswers = val
    }
  },
  actions: {
    backToDayPicker({ commit }) {
      commit('setSelectedDay', null)
      commit('setSelectedTime', null)
      commit('setSelectedRoom', null)
      commit('setSelectedActivity', null)
      commit('setReservationDetails', null)
      commit('setTimes', [])
      commit('setStep', 1)
    },
    clearError() {
      commit('setError', null)
    }
  },
  getters: {
    timesApiParams: state => {
      if (state.selectedDay == null) return

      return {
        date: state.selectedDay.id
      }
    },
    slotId: state => {
      if (state.selectedTime == null) return
      return state.selectedTime.slotId
    }
  }
})
