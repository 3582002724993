export default {
  holdSlot(data, cb) {
    const path = '/api/v1/booking_holds.json'
    fetch(path, {
      method: 'POST',
      credentials: 'same-origin',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  holdSlotWithTimes(data, cb) {
    const path = '/api/v1/booking_holds/from_times.json'
    fetch(path, {
      method: 'POST',
      credentials: 'same-origin',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  getSlot(slotCode, cb) {
    const path = `/api/v1/booking_holds/${slotCode}.json`
    fetch(path, {
      method: 'GET'
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  addStaffMember(slotCode, staffCode, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/staff.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: staffCode })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  removeStaffMember(slotCode, staffCode, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/staff/destroy.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: staffCode })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  addExtra(slotCode, extraCode, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/add_extra.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: extraCode })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  removeExtra(slotCode, extraCode, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/remove_extra.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: extraCode })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  changeExtraQuantity(slotCode, extraCode, quantity, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/change_extra_quantity.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: extraCode, quantity: quantity })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  addCoupon(slotCode, body, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/add_coupon.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  discoverCustomerCoupons(holdCode, body, cb) {
    const path = `/api/v1/booking_holds/${holdCode}/discover_customer_coupons.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
}
