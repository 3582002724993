import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/times/:date',
      name: 'Times',
      component: () => import(/* webpackChunkName: "Times" */'./views/Times.vue')
    },
    {
      path: '/reserve_from_booking_app/:code',
      name: 'ReservationFromBookingApp',
      component: () => import(/* webpackChunkName: "ReservationFromBookingApp" */'./views/ReservationFromBookingApp.vue')
    },
    {
      path: '/reserve/:code',
      name: 'ReservationAuthenticated',
      component: () => import(/* webpackChunkName: "ReservationAuthenticated" */'./views/ReservationAuthenticated.vue')
    },
    {
      path: '/reserve_as_guest/:code',
      name: 'ReservationGuest',
      component: () => import(/* webpackChunkName: "ReservationGuest" */'./views/ReservationGuest.vue')
    },
    {
      path: '/call_to_reserve/:code',
      name: 'CallToReserve',
      component: () => import(/* webpackChunkName: "CallToReserve" */'./views/CallToReserve.vue')
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

