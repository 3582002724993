const path = '/api/v1/bookings.json'
const fetch = window.fetch

export default {
  makeBooking (params, cb) {
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then( (resp) => {
      return resp.json()
    }).then( (json) => cb(json) )
  }
}
