const path = '/api/v1/csrf.json'
const fetch = window.fetch

export default {
  getToken(cb) {
    fetch(path, {
      method: 'GET'
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json.token))
  }
}
