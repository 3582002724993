const fetch = window.fetch
const esc = encodeURIComponent

export default {
  getTimes (params, cb) {
    const path = '/api/v1/times.json'
    const queryString = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&')
    const url = path + '?' + queryString

    fetch(url)
      .then( (resp) => {
        return resp.json()
      }).then( (json) => cb(json) )
  },

  getReservation (id, cb) {
    const url = '/api/v1/times/' + id + '.json'

    fetch(url)
      .then( (resp) => {
        return resp.json()
      }).then( (json) => cb(json) )
  },

  getAllDayTimes (params, cb) {
    const path = '/api/v1/all_day_availability.json'
    const queryString = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&')
    const url = path + '?' + queryString

    fetch(url)
      .then( (resp) => {
        return resp.json()
      }).then( (json) => cb(json) )
  }
}
