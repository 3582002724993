const fetch = window.fetch

export default {
  me(cb) {
    const path = '/customers/session.json'
    fetch(path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  }
}
